<template>
	<!-- NOTE: Webview以外なら表示 -->
	<div v-if="!isWebview" class="sugotoku-mypage-stamp-link">
		<div v-if="isMyPage" class="sugotoku-mypage-stamp-link__inner">
			<a class="sugotoku-mypage-stamp-link__link" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'mypage', _backPage: 'top' } })" ><span>マイページ登録特典スタンプはコチラ</span><img src="@/assets/img/sugotoku-mypage-stamp-link/icon03.svg" alt="プレゼントアイコン"></a>
		</div>
		<div v-else class="sugotoku-mypage-stamp-link__inner">
			<h2 class="sugotoku-mypage-stamp-link__title"><img src="@/assets/img/sugotoku-mypage-stamp-link/icon01.svg" alt="プレゼントアイコン"><span><span>&#9660;</span>マイページに追加で<span>&#9660;</span><br>限定スタンププレゼント！</span><img src="@/assets/img/sugotoku-mypage-stamp-link/icon02.svg" alt="マロアイコン"></h2>
			<p v-if="isClickMypage" class="sugotoku-mypage-stamp-link__text">マイページに追加したらページを更新してください</p>
			<div v-else :class="[
				'dcm_favorite_separateButton',
				'dcm_typeRed',
				'mypage__button',
				'sugotoku-mypage-stamp-link__button',
				localClass,
			]">
				<p>
					<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SugotokuMypageStampLink',
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			localClass: '',
			isClickMypage: false
		}
	},
	mounted () {
		this.localClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isMyPage', 'isWebview'])
	},
	methods: {
		// map Vuex actions

		/**
		 * マイページ登録ボタンクリック処理
		 */
		clickMypageButton (e) {
			this.isClickMypage = true

			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// NOTE: 新宿の母を見るとマイページ登録後にボタンを非表示にしていないので、非表示にしない。もし非表示にする場合、コンテンツの高さを再取得してthis.SET_IS_MY_PAGE(true)をする必要がある。
			// this.SET_IS_MY_PAGE(true)

			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		}
	},
	beforeDestroy () {
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

// NOTE: デザインシステム適用外
$sugotoku-mypage-stamp-link-red: #cb0034;
$sugotoku-mypage-stamp-link-background: #FFF8BE;
$font-size-15: 0.937rem;
$spacing-14: 0.875rem;

// マイページ登録限定スタンプリンク
.sugotoku-mypage-stamp-link {
	font-family: $lp-family-secondary;
	background-color: $sugotoku-mypage-stamp-link-background;
	padding: $spacing-14;
	margin: $spacing-20 auto 0;
	font-size: $font-size-15;

	@media (max-width: (374px)) {
		font-size: $font-size-12;
	}

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		font-size: $font-size-15;
		text-align: center;
		font-weight: normal;
		line-height: $line-height;

		& > span {
			padding-left: $spacing-10;

			& > span { color: $sugotoku-mypage-stamp-link-red; }
		}

		@media (max-width: (360px)) {
			font-size: $font-size-12;
		}
	}

	&__button p {
		margin: $spacing-6 auto 0;
	}

	&__text {
		margin: $spacing-10 auto;
		text-align: center;
		color: $sugotoku-mypage-stamp-link-red;
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: $spacing-20 auto;
		padding: $spacing-6 $spacing-10;
		width: 86.4%;
		background-color: $background-primary;
		border: 4px solid $sugotoku-mypage-stamp-link-red;
		border-radius: 8px;
		text-align: center;
		line-height: $line-height-1;
		color: $sugotoku-mypage-stamp-link-red;

		& > img { margin-left: $spacing-8; }
	}

	// NOTE: スゴ得マイページ登録ボタンのローカル確認用
	.is-local {
		&.mypage__button {
			box-sizing: border-box;

			p { text-align: center; }

			a {
				display: block;
				padding: 14px 10px;
				font-size: 12px;
				text-decoration: none;
				color: #000000;
				border-radius: 10px;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
			}

			&.dcm_typeRed a {
				color: #fff;
				background: linear-gradient(to bottom, #fe5c85 0%, #fd2e62 5%, #a00117 95%, #5c000b 100%);
			}
		}
	}
}

</style>
