<template>
	<div class="sugotoku-top-weather-forecast">
		<h2 class="sugotoku-top-weather-forecast__title about-column__title">
			<p>{{ cityName }}</p>
			<a class="sugotoku-top-weather-forecast__point-registration" :id="[isAndroid ? 'trackingSugotokuTwodaysAndroidBtn01' : 'trackingSugotokuTwodaysIosBtn01']" href="javascript:void(0);" @click="clickPointRegistration($event)">地域変更</a>
		</h2>
		<section class="sugotoku-top-weather-forecast__wrapper">
			<!-- 今日明日の気圧予報 -->
			<two-days-pressure-forecast @catchCityName="inputCityName" />
			<!-- /今日明日の気圧予報 -->
			<div class="about-two-days__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingSugotokuTopTwoDaysBtn_android" class="about-two-days__login--button">1時間ごとの気圧予報はこちら！</a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=weatherGraph` } })" id="trackingSugotokuTopTwoDaysBtn_iOS" class="about-two-days__login--button">1時間ごとの気圧予報はこちら！</a>
			</div>
			<!-- 健康天気指数 -->
			<health-weather />
			<!-- /健康天気指数 -->
		</section>
	</div>
</template>

<script>

// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

// Compornents
import TwoDaysPressureForecast from '@/components/Organisms/TwoDaysPressureForecast'
import HealthWeather from '@/components/Organisms/HealthWeather'

export default {
	name: 'WeatherContent',
	components: {
		TwoDaysPressureForecast,
		HealthWeather
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			cityName: ''
		}
	},
	mounted () {
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	methods: {
		// 地点登録ボタンのクリックイベント
		clickPointRegistration (e) {
			// aタグデフォルトイベントを止めてルーティング
			e.preventDefault()
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=pointRegistration` } })
		},

		// 子コンポーネントから受け取った地点名を変数に代入する
		inputCityName (name) {
			this.cityName = `${name}の天気予報`
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

// NOTE: デザインシステム外のため他での利用禁止
$two-days-pressure-forecas-title: #0FA298;
$font-size-13: 0.812rem;
$spacing-3: 0.187rem;
$spacing-5: 0.312rem;
$spacing-7: 0.437rem;
$spacing-12: 0.750rem;

.sugotoku-top-weather-forecast {
	&__title {
		display: flex;
		justify-content: space-between;
		margin: 0 $spacing-10;

		p {
			margin: 0;
			font-size: $font-size-16;
			font-family: $lp-family-tertiary;
			@media (max-width: (370px)) {
				font-size: $font-size-13;
			}
		}
	}

	&__point-registration {
		padding: $spacing-5 $spacing-7;
		margin: 0 0 $spacing-3 0;
		color: $two-days-pressure-forecas-title;
		font-size: $font-size-10;
		font-weight: normal;
		text-decoration: none;
		line-height: $line-height-1;
		border: 1px solid $two-days-pressure-forecas-title;
		border-radius: 4px;

		&:active {
			background: $two-days-pressure-forecas-title;
			color: $text-seconday;
		}
	}

	&__wrapper {
		/deep/ .health-weather__wrapper {
			margin: 0;
		}
		/deep/ .health-weather__title {
			margin: $spacing-20 0 $spacing-10;
			padding-left: $spacing-12;
			font-size: $font-size-13;
			font-family: $lp-family-primary;
			position: relative;
			border: none;
			color: #333;
			&::before {
				content: "";
				display: inline-block;
				width: 6px;
				height: 16px;
				background-color: $primary;
				position: absolute;
				top: 1px;
				left: 0;
			}
		}
	}
}

</style>
