<template>
	<aside class="sugotoku-multiple-diagnosis-service">
		<h2 class="about-column__title">医師監修・頭痛ーる診断</h2>
		<div class="sugotoku-multiple-diagnosis-service__wrapper">
			<ul class="sugotoku-multiple-diagnosis-service__list">
				<li
					v-for="(item, index) in contentList"
					:key="`sugotoku-multiple-diagnosis-service-${index}`"
					@click="$router.push({ name: 'Cpsite', query: { url: item.link_url } })"
					:class="index < 2 ? '' : 'list-item-space'"
					class="sugotoku-multiple-diagnosis-service__item"
				>
					<div class="sugotoku-multiple-diagnosis-service__item--group">
						<img :src="item.src" :alt="item.alt">
						<p>{{ item.text }}</p>
					</div>
				</li>
			</ul>
		</div>
	</aside>
</template>

<script>

export default {
	name: 'MultipleDiagnosisService',
	data () {
		return {
			// NOTE: 表示したいコンテンツを下記に追記する
			contentList: [
				{
					link_url: `${this.backendUrlProps}?_path=headcheTypeContent`,
					src: require('@/assets/img/multiple-diagnosis-service-img/headacheType.png'),
					alt: 'あなたの頭痛タイプ診断',
					text: '頭痛の原因はさまざま。まずは自分のタイプを知ろう'
				},
				{
					link_url: `${this.backendUrlProps}?_path=selfcheckMeteoropathy`,
					src: require('@/assets/img/multiple-diagnosis-service-img/meteorologicalDisease.png'),
					alt: '医師監修 気象病セルフチェック',
					text: '雨の日に体調不良になりやすい人におすすめ'
				},
				{
					link_url: `${this.backendUrlProps}?_path=balancecheckAutonomicNerves`,
					src: require('@/assets/img/multiple-diagnosis-service-img/autonomicNerves.png'),
					alt: '自律神経のバランスチェック',
					text: '慢性的な体調不良は自律神経の乱れが原因かも⁉'
				},
				{
					link_url: `${this.backendUrlProps}?_path=selfcheckTemperatureDifference`,
					src: require('@/assets/img/multiple-diagnosis-service-img/temperatureDifference.png'),
					alt: '寒暖差疲労セルフチェック',
					text: '季節の変わり目に弱い人は要チェック'
				},
				{
					link_url: `${this.backendUrlProps}?_path=stresscheckDiagnosis`,
					src: require('@/assets/img/multiple-diagnosis-service-img/stressCheck.png'),
					alt: 'ストレスチェック',
					text: 'コロナ・在宅などストレスがかかりがち。チェックを'
				}
			]
		}
	},
	props: {
		backendUrlProps: {
			type: String,
			default: ''
		}
	},
	mounted () {
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

.sugotoku-multiple-diagnosis-service {
	margin-top: $spacing-20;
	padding: 0 $spacing-10;

	&__wrapper {
		width: 100%;
	}

	&__list {
		padding: $spacing-4;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow-wrap: break-word;
	}

	&__item {
		width: calc(50% - #{$spacing-4});
		list-style: none;
		&--group {
			margin: 0 auto;
			max-width: 160px;
			width: 100%;
			img {
				max-width: 160px;
				width: 100%;
			}
			p {
				max-width: 160px;
				width: 100%;
				margin: 0;
				font-family: $lp-family-primary;
				font-size: $font-size-12;
			}
		}
	}
}

.list-item-space {
	margin-top: $spacing-16;
}

</style>
