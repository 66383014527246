<template>
	<aside class="two-days-pressure-forecast__wrapper">
		<h3 class="two-days-pressure-forecast__title">気圧予報</h3>
		<div class="two-days-pressure-forecast">
			<!-- ローディング -->
			<transition name="fade">
				<redraw-progress v-if="isLoading" />
			</transition>
			<!-- /ローディング -->
			<div v-if="!isError && pressureForecast" class="two-days-pressure-forecast__inner">
				<div class="two-days-pressure-forecast__today">
					<div class="two-days-pressure-forecast__date">
						<h4>今日</h4>
						<span>{{ pressureForecast.today.date }}</span><span :class="`is-${pressureForecast.today.dayOfWeekColor}`">{{ pressureForecast.today.dayOfWeek }}</span>
					</div>
					<div class="two-days-pressure-forecast__forecast">
						<div class="two-days-pressure-forecast__forecast--left">
							<i :class="`two-days-pressure-forecast__pressure-level sprite sprite_kiatu_icon_lv${pressureForecast.today.forecast.pressure.level}`" />
						</div>
						<div class="two-days-pressure-forecast__forecast--right">
							<p class="two-days-pressure-forecast__weather">
								<span><i :class="`sprite sprite_tenki_${pressureForecast.today.forecast.weather.code}`" /></span>
							</p>
							<p class="two-days-pressure-forecast__temperature">
								<span class="two-days-pressure-forecast__temperature--max">{{ pressureForecast.today.forecast.temperature.max }}<span class="is-symbol">&#8451;</span></span><br>
								<span class="two-days-pressure-forecast__temperature--min">{{ pressureForecast.today.forecast.temperature.min }}<span class="is-symbol">&#8451;</span></span>
							</p>
							<p class="two-days-pressure-forecast__rain-icon"><span><i class="sprite sprite_tenki_icon" /></span></p>
							<p class="two-days-pressure-forecast__precipitation"><span>{{ pressureForecast.today.forecast.weather.precipitation }}<span class="is-symbol">&#37;</span></span></p>
						</div>
					</div>
				</div>
				<div class="two-days-pressure-forecast__tomorrow">
					<div class="two-days-pressure-forecast__date">
						<h4>明日</h4>
						<span>{{ pressureForecast.tomorrow.date }}</span><span :class="`is-${pressureForecast.tomorrow.dayOfWeekColor}`">{{ pressureForecast.tomorrow.dayOfWeek }}</span>
					</div>
					<div class="two-days-pressure-forecast__forecast">
						<div class="two-days-pressure-forecast__forecast--left">
							<i :class="`two-days-pressure-forecast__pressure-level sprite sprite_kiatu_icon_lv${pressureForecast.tomorrow.forecast.pressure.level}`" />
						</div>
						<div class="two-days-pressure-forecast__forecast--right">
							<p class="two-days-pressure-forecast__weather">
								<span><i :class="`sprite sprite_tenki_${pressureForecast.tomorrow.forecast.weather.code}`" /></span>
							</p>
							<p class="two-days-pressure-forecast__temperature">
								<span class="two-days-pressure-forecast__temperature--max">{{ pressureForecast.tomorrow.forecast.temperature.max }}<span class="is-symbol">&#8451;</span></span><br>
								<span class="two-days-pressure-forecast__temperature--min">{{ pressureForecast.tomorrow.forecast.temperature.min }}<span class="is-symbol">&#8451;</span></span>
							</p>
							<p class="two-days-pressure-forecast__rain-icon"><span><i class="sprite sprite_tenki_icon" /></span></p>
							<p class="two-days-pressure-forecast__precipitation"><span>{{ pressureForecast.tomorrow.forecast.weather.precipitation }}<span class="is-symbol">&#37;</span></span></p>
						</div>
					</div>
				</div>
			</div>
			<!-- エラー処理 -->
			<p v-else class="two-days-pressure-forecast__error">{{ errorMessage }}</p>
			<!-- /エラー処理 -->
		</div>
	</aside>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA, SET_S3_CITY_CODE_DATA } from '@/store/modules/common/mutation-types'

// Compornents
import RedrawProgress from '@/components/Molecules/RedrawProgress'

// ライブラリ
import axios from 'axios'
import moment from 'moment'
import cmnConst from '@/assets/js/constant.js'

// Utils
import MunicipalCodeUtil from '@/utils/municipal-code-util'

export default {
	name: 'TwoDaysPressureForecast',
	components: {
		RedrawProgress
	},
	data () {
		return {
			pressureForecast: null,
			errorMessage: '',
			isLoading: false,
			isError: false
		}
	},
	mounted () {
		const apiArray = []
		// CHANGE: 変数に定義した時点でAPIリクエストが走ってしまう。リクエストを減らしたいので変数に定義せず配列に直接Pushする。
		// データを持ってなければPromise.allするAPI配列に追加
		if (!this.userData) {
			apiArray.push(this.SET_USER_DATA({
				user_id: this.userId,
				user_token: this.userToken
			}))
		}
		if (!this.s3CityCodeData) apiArray.push(this.SET_S3_CITY_CODE_DATA())

		// リクエストするAPIがあればリクエストして次の処理に、なければそのまま次の処理に進む
		if (apiArray.length > 0) {
			Promise.all(apiArray).then(res => {
				// console.log('userData: ', this.userData)
				// console.log('s3CityCodeData: ', this.s3CityCodeData)
				this.getPressureForecast()
			}).catch(error => {
				console.log('Error SET_USER_DATA: ', error)
			})
		} else {
			this.getPressureForecast()
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'userData', 's3CityCodeData'])
	},
	methods: {
		...mapActions('common', [SET_USER_DATA, SET_S3_CITY_CODE_DATA]),

		/**
		 * 今日明日の気圧予報を取得する処理
		 */
		getPressureForecast () {
			// NOTE: storeにデータを保存する必要がないため直接リクエストする（今後、必要ならstoreに移動）
			this.isLoading = true
			axios.request({
				method: 'post',
				url: `${cmnConst.API_URL}/api/v2/getweatherdirect2days.json`,
				params: {
					user_id: this.userId,
					user_token: this.userToken
				}
			}).then(res => {
				if (res.data.result_code === 4000 || res.data.result_code === 4001) {	// 4000エラー（地点系エラー？）、4001エラー（パラメータ系エラー）
					console.log('4000 error: ', res)
					this.isError = true
					// this.errorMessage = res.data.result_message
					this.errorMessage = 'データを取得できませんでした。ページを更新してください。'
				} else if (res.data.forecasts) {	// データ取得OK
					// 地点名を都道府県＋市町村名で取得する
					const municipalCodeUtil = new MunicipalCodeUtil(this.s3CityCodeData)
					const cityCode = this.userData && this.userData.city_code ? this.userData.city_code : '13113'	// デフォルトは「東京都渋谷区」
					const municipalData = municipalCodeUtil.getMunicipalData(cityCode)
					this.$emit('catchCityName', `${municipalData.prefecture_name}${municipalData.name}`)

					// 気圧予報取得
					const forecast = res.data.forecasts
					const today = moment(String(forecast[0].date))
					const tomorrow = moment(String(forecast[1].date))
					// 日付を扱いやすいようにしたオブジェクトを作る
					this.pressureForecast = {
						today: {
							date: today.format('MM/DD'),
							dayOfWeek: today.format('（dd）'),
							dayOfWeekColor: today.format('d') === '0' ? 'red' : today.format('d') === '6' ? 'blue' : 'normal',
							forecast: this.convertWeatherCode(forecast[0])
						},
						tomorrow: {
							date: tomorrow.format('MM/DD'),
							dayOfWeek: tomorrow.format('（dd）'),
							dayOfWeekColor: tomorrow.format('d') === '0' ? 'red' : tomorrow.format('d') === '6' ? 'blue' : 'normal',
							forecast: this.convertWeatherCode(forecast[1])
						}
					}
					console.log('pressureForecast: ', this.pressureForecast)
				} else {	// それ以外のエラー
					console.log('Not found forecasts error: ', res)
					this.isError = true
					this.errorMessage = 'データを取得できませんでした。ページを更新してください。'
				}
			}).catch(error => {
				console.log('error: ', error)
				this.isError = true
			}).finally(() => {
				this.isLoading = false
			})
		},

		/**
		 * アイコンが同じなので「〇一時〇」の天気コードを「〇時々〇」に書き換える関数
		 */
		convertWeatherCode (forecast) {
			switch (forecast.weather.code) {
				case '102':
					forecast.weather.code = '103'
					break
				case '104':
					forecast.weather.code = '105'
					break
				case '202':
					forecast.weather.code = '203'
					break
				case '204':
					forecast.weather.code = '205'
					break
				case '309':
					forecast.weather.code = '303'
					break
				case '409':
					forecast.weather.code = '403'
					break
			}
			return forecast
		}
	},
	beforeDestroy () {
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";
// NOTE: デザインシステム外のため他での利用禁止
$two-days-pressure-forecas-title: #0FA298;
$two-days-pressure-forecas-text: #333;
$two-days-pressure-forecas-color-red: #FF4E00;
$two-days-pressure-forecas-color-blue:  #55B1FF;
$font-size-11: 0.687rem;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$spacing-3: 0.187rem;
$spacing-5: 0.312rem;
$spacing-7: 0.437rem;
$spacing-12: 0.75rem;
$spacing-15: 0.937rem;

.two-days-pressure-forecast {
	$parent: &;
	position: relative;
	max-width: 355px;
	min-height: 116px;
	margin: 0 auto;
	color: $two-days-pressure-forecas-text;

	&__wrapper { padding: 0 $spacing-10; }

	&__title {
		margin: $spacing-10 0;
		padding-left: $spacing-12;
		font-size: $font-size-13;
		font-family: $lp-family-primary;
		position: relative;
		&::before {
			content: "";
			display: inline-block;
			width: 6px;
			height: 16px;
			background-color: $primary;
			position: absolute;
			top: 1px;
			left: 0;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		margin: $spacing-10 auto 0;
	}

	&__today, &__tomorrow {
		width: 50%;
		box-sizing: border-box;
	}

	&__today {
		border-right: 1px solid $line-primary;
		padding: 0 0 0 $spacing-6;
	}

	&__tomorrow {
		padding: 0 0 0 $spacing-10;
	}

	&__date {
		margin: $spacing-10 $spacing-12 0 0;
		text-align: center;
		font-size: $font-size-14;
		line-height: $line-height-1;

		#{$parent}__tomorrow & { margin: $spacing-10 $spacing-4 0 0; }

		h4 {
			display: inline-block;
			margin: 0 $spacing-15 0 0;
			font-size: $font-size-15;
		}

		.is-red { color: $two-days-pressure-forecas-color-red; }
		.is-blue { color: $two-days-pressure-forecas-color-blue; }
	}

	&__forecast {
		display: flex;
		justify-content: flex-start;
		height: 76px;
		margin: $spacing-15 0 0 0;

		&--left {
			flex-basis: 70px;
			overflow: hidden;
			flex-grow: 0;
			box-sizing: border-box;
		}

		&--right {
			display: flex;
			justify-content: flex-start;
			flex-grow: 1;
			flex-wrap: wrap;
			padding: 0 $spacing-12 0 $spacing-8;
			box-sizing: border-box;

			@media (max-width: (360px)) {
				padding: 0 $spacing-6 0 $spacing-4;
			}

			#{$parent}__tomorrow & { padding: 0 $spacing-6 0 $spacing-8; }

			> * {
				width: 50%;
				margin: 0;
				box-sizing: border-box;
			}
		}
	}

	&__pressure-level {
		transform-origin: 0 0;
		transform: scale(.5983);	// 70px(元117px)
	}

	&__weather, &__temperature { height: 50px; }
	&__rain-icon, &__precipitation { height: 26px; }

	&__weather {
		display: block;

		& > span {
			display: block;
			width: 46px;
			height: 46px;
			margin: 0 auto;
			overflow: hidden;

			& > .sprite {
				transform-origin: 0 0;
				transform: scale(.561);	// 46px(元82px)
			}

			@media (max-width: (360px)) {
				width: 26.5px;
				height: 26.5px;
				padding: $spacing-10 0 0 0;
				& > .sprite {
					transform: scale(.3232);	// 26.5px(元82px)
				}
			}
		}
	}

	&__temperature {
		padding: $spacing-2 0 0 0;
		text-align: right;
		font-size: $font-size-15;

		&--max,
		&--min {
			line-height: $line-height-1;
		}
		&--max {
			color: $two-days-pressure-forecas-color-red;
		}
		&--min {
			color: $two-days-pressure-forecas-color-blue;
		}
	}

	&__rain-icon {
		position: relative;

		& > span {
			display: block;
			position: absolute;
			width: 16px;
			height: 16.5px;
			left: calc(50% + 2px);
			bottom: $spacing-5;
			transform: translateX(-50%);
			overflow: hidden;

			@media (max-width: (360px)) {
				left: calc(50% - 2px);
			}

			& > .sprite {
				transform-origin: 0 0;
				transform: scale(.51613);	// 16px(元31px)
			}
		}
	}

	&__precipitation {
		position: relative;
		text-align: right;
		font-size: $font-size-15;

		& > span {
			display: block;
			position: absolute;
			right: 0;
			bottom: $spacing-5;
			line-height: $line-height-1;;
		}
	}

	&__error { margin: 0; }

	.is-symbol {font-size:  $font-size-11; }
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

// スプライト
.sprite {
    background-image: url(~@/assets/img/sprite_2days_pressure_forecast.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite_kiatu_icon_lv1 {
    width: 117px;
    height: 126px;
    background-position: 0 0;
}

.sprite_kiatu_icon_lv2 {
    width: 117px;
    height: 126px;
    background-position: 0 -126px;
}

.sprite_kiatu_icon_lv3 {
    width: 117px;
    height: 126px;
    background-position: 0 -252px;
}

.sprite_kiatu_icon_lv4 {
    width: 117px;
    height: 126px;
    background-position: 0 -378px;
}

.sprite_kiatu_icon_lv5 {
    width: 117px;
    height: 126px;
    background-position: 0 -504px;
}

.sprite_kiatu_icon_lv6 {
    width: 117px;
    height: 126px;
    background-position: 0 -630px;
}

.sprite_tenki_100 {
    width: 82px;
    height: 82px;
    background-position: 0 -756px;
}

.sprite_tenki_101 {
    width: 82px;
    height: 82px;
    background-position: 0 -838px;
}

.sprite_tenki_103 {
    width: 82px;
    height: 82px;
    background-position: 0 -920px;
}

.sprite_tenki_105 {
    width: 82px;
    height: 82px;
    background-position: 0 -1002px;
}

.sprite_tenki_111 {
    width: 82px;
    height: 82px;
    background-position: 0 -1084px;
}

.sprite_tenki_114 {
    width: 82px;
    height: 82px;
    background-position: 0 -1166px;
}

.sprite_tenki_117 {
    width: 82px;
    height: 82px;
    background-position: 0 -1248px;
}

.sprite_tenki_200 {
    width: 82px;
    height: 82px;
    background-position: 0 -1330px;
}

.sprite_tenki_201 {
    width: 82px;
    height: 82px;
    background-position: 0 -1412px;
}

.sprite_tenki_202 {
    width: 82px;
    height: 82px;
    background-position: 0 -1494px;
}

.sprite_tenki_203 {
    width: 82px;
    height: 82px;
    background-position: 0 -1576px;
}

.sprite_tenki_204 {
    width: 82px;
    height: 82px;
    background-position: 0 -1658px;
}

.sprite_tenki_205 {
    width: 82px;
    height: 82px;
    background-position: 0 -1740px;
}

.sprite_tenki_211 {
    width: 82px;
    height: 82px;
    background-position: 0 -1822px;
}

.sprite_tenki_214 {
    width: 82px;
    height: 82px;
    background-position: 0 -1904px;
}

.sprite_tenki_217 {
    width: 82px;
    height: 82px;
    background-position: 0 -1986px;
}

.sprite_tenki_300 {
    width: 82px;
    height: 82px;
    background-position: 0 -2068px;
}

.sprite_tenki_301 {
    width: 82px;
    height: 82px;
    background-position: 0 -2150px;
}

.sprite_tenki_302 {
    width: 82px;
    height: 82px;
    background-position: 0 -2232px;
}

.sprite_tenki_303 {
    width: 82px;
    height: 82px;
    background-position: 0 -2314px;
}

.sprite_tenki_311 {
    width: 82px;
    height: 82px;
    background-position: 0 -2396px;
}

.sprite_tenki_313 {
    width: 82px;
    height: 82px;
    background-position: 0 -2478px;
}

.sprite_tenki_315 {
    width: 82px;
    height: 82px;
    background-position: 0 -2560px;
}

.sprite_tenki_400 {
    width: 82px;
    height: 82px;
    background-position: 0 -2642px;
}

.sprite_tenki_401 {
    width: 82px;
    height: 82px;
    background-position: 0 -2724px;
}

.sprite_tenki_402 {
    width: 82px;
    height: 82px;
    background-position: 0 -2806px;
}

.sprite_tenki_403 {
    width: 82px;
    height: 82px;
    background-position: 0 -2888px;
}

.sprite_tenki_411 {
    width: 82px;
    height: 82px;
    background-position: 0 -2970px;
}

.sprite_tenki_413 {
    width: 82px;
    height: 82px;
    background-position: 0 -3052px;
}

.sprite_tenki_414 {
    width: 82px;
    height: 82px;
    background-position: 0 -3134px;
}

.sprite_tenki_icon {
    width: 31px;
    height: 32px;
    background-position: 0 -3216px;
}
</style>
