<template>
<div class="sugotoku-link">
	<ul class="about-footer__list">
		<li class="about-footer__item"><span @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=terms` } })" class="about-footer__anchor">利用規約</span></li>
		<li class="about-footer__item"><a href="https://www.sugotoku.docomo.ne.jp/cs/faq.html" class="about-footer__anchor">よくあるご質問/お問い合わせ</a></li>
	</ul>
	<ul class="about-footer__list">
		<li class="about-footer__item"><a href="https://www.sugotoku.docomo.ne.jp/cs/representation.html" class="about-footer__anchor">提供事業者等に関する表示</a></li>
	</ul>
	<ul class="about-footer__list">
		<li class="about-footer__item"><span @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=sendingPersonalData` } })" class="about-footer__anchor">お客さまご利用端末からの 情報の外部送信について</span></li>
	</ul>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SugotokuLink',
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
