<template>
	<div class="health-weather__wrapper" id="js_healthweather">
		<h3 class="health-weather__title">健康天気指数</h3>

		<div class="health-weather">

			<!-- ローディング -->
			<transition name="fade">
				<redraw-progress v-if="isLoading" />
			</transition>
			<!-- /ローディング -->

			<!-- 健康天気指数 -->
			<div v-if="!isError && getWeatherData" class="health-weather__inner">
				<!-- 日付 -->
				<ul class="health-weather__tab">
					<v-ons-segment :index.sync="segmentIndex">
						<li><input class="segment__input" type="radio" value="0" name="ons-segment-gen-1" id="trackingHealthWeatherBtnTab01">{{ computedHealthWeatherData.today.date }}（{{ computedHealthWeatherData.today.week_day }}）</li>
						<li><input class="segment__input" type="radio" value="1" name="ons-segment-gen-1" id="trackingHealthWeatherBtnTab02">{{ computedHealthWeatherData.tomorrow.date }}（{{ computedHealthWeatherData.tomorrow.week_day }}）</li>
					</v-ons-segment>
				</ul>
				<!-- /日付 -->

				<!-- 指数 -->
				<div class="health-weather__day" v-for="(item, index) in computedHealthWeatherData.data" :key="`day-${index}`">
					<ul class="health-weather__list" :class="segmentIndex === index ? 'is-active' : ''">
						<li :class="`is-order${computedHealthWeatherData.schdule[0]}`">
							<h3 class="health-weather__list--title">花粉</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon pollen_icon">
									<i :class="`sprite_health_weather_icon_lv pollen_icon_lv pollen_icon_lv${item.pollen_level}`" />
								</span>
								<p>{{ item.pollen_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[1]}`">
							<h3 class="health-weather__list--title">インフルエンザ</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon inful_icon">
									<i :class="`sprite_health_weather_icon_lv inful_icon_lv inful_icon_lv${item.inful_level}`" />
								</span>
								<p>{{ item.inful_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[2]}`">
							<h3 class="health-weather__list--title">乾燥</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon kanso_icon">
									<i :class="`sprite_health_weather_icon_lv kanso_icon_lv kanso_icon_lv${item.kanso_level}`" />
								</span>
								<p>{{ item.kanso_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[3]}`">
							<h3 class="health-weather__list--title">うつ</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon utsu_icon">
									<i :class="`sprite_health_weather_icon_lv utsu_icon_lv utsu_icon_lv${item.utsu_day}`" />
								</span>
								<p>{{ item.utsu_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[4]}`">
							<h3 class="health-weather__list--title">寒暖差</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon temp_icon">
									<i :class="`sprite_health_weather_icon_lv temp_icon_lv temp_icon_lv${item.temp_difference_level}`" />
								</span>
								<p>{{ item.temp_difference_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[5]}`">
							<h3 class="health-weather__list--title">紫外線</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon uv_icon">
									<i :class="`sprite_health_weather_icon_lv uv_icon_lv uv_icon_lv${item.uv_level}`" />
								</span>
								<p>{{ item.uv_comment }}</p>
							</div>
						</li>
						<li :class="`is-order${computedHealthWeatherData.schdule[6]}`">
							<h3 class="health-weather__list--title">熱中症</h3>
							<div class="health-weather__list--contents">
								<span class="sprite_health_weather_icon netu_icon">
									<i :class="`sprite_health_weather_icon_lv netu_icon_lv netu_icon_lv${item.netu_move}`" />
								</span>
								<p>{{ item.netu_comment }}</p>
							</div>
						</li>
					</ul>
				</div>
				<!-- /指数 -->
			</div>
			<!-- /健康天気指数 -->

			<!-- スゴ得エラー処理 -->
			<p v-else-if="isError" class="health-weather__error">データを取得できませんでした。ページを更新してください。</p>
			<!-- /スゴ得エラー処理 -->
		</div>
	</div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_HEALTH_WEATHER } from '@/store/modules/weatherForecast/mutation-types'

// Compornents
import RedrawProgress from '@/components/Molecules/RedrawProgress'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'
moment.locale('ja')

export default {
	name: 'HealthWeather',
	components: {
		RedrawProgress
	},
	data () {
		return {
			isLoading: false,
			isError: false,
			segmentIndex: 0,
			backendUrl: cmnConst.BACKEND_URL
		}
	},
	created () {
	},
	mounted () {
		// データの取得
		this.getWeatherData()
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken']),
		...mapGetters('weatherForecast', ['healthWeatherData']),

		// 日付・健康天気指数のデータを扱いやすいよう生成
		computedHealthWeatherData () {
			const todayMonth = Number(moment().month())

			// 健康天気指数のスケジュール
			const healthWeatherSchdule = [
				[0, 2, 1, 4, 3, 0, 0], // 1月
				[2, 1, 3, 5, 4, 0, 0], // 2月
				[1, 0, 2, 5, 4, 3, 0], // 3月
				[1, 0, 2, 5, 4, 3, 0], // 4月
				[0, 0, 2, 5, 4, 1, 3], // 5月
				[0, 0, 0, 4, 3, 2, 1], // 6月
				[0, 0, 0, 4, 3, 2, 1], // 7月
				[0, 0, 0, 4, 3, 2, 1], // 8月
				[0, 0, 0, 4, 3, 2, 1], // 9月
				[0, 0, 2, 4, 3, 1, 0], // 10月
				[0, 2, 1, 4, 3, 0, 0], // 11月
				[0, 2, 1, 4, 3, 0, 0] // 12月
			]

			// 各種デフォルト値を設定
			const data = {
				today: { date: '-/-', week_day: '-' },
				tomorrow: { date: '-/-', week_day: '-' },
				schdule: healthWeatherSchdule[todayMonth],
				data: [{
					uv_level: 0,
					uv_comment: '―',
					utsu_day: 0,
					utsu_comment: '―',
					kanso_level: 0,
					kanso_comment: '―',
					pollen_level: 0,
					pollen_comment: '―',
					inful_level: 0,
					inful_comment: '―',
					netu_move: 0,
					netu_comment: '―',
					temp_difference_level: 0,
					temp_difference_comment: '―'
				}, {}]
			}

			if (this.healthWeatherData) {
				const records = this.healthWeatherData.records
				let year = Number(records[0].date.substring(0, 4))
				let month = Number(records[0].date.substring(4, 6)) - 1
				let day = Number(records[0].date.substring(6, 8))
				const today = moment().year(year).month(month).date(day)

				year = Number(records[1].date.substring(0, 4))
				month = Number(records[1].date.substring(4, 6)) - 1
				day = Number(records[1].date.substring(6, 8))
				const tomorrow = moment().year(year).month(month).date(day)

				data.today.date = today.format('M/D')
				data.today.week_day = today.format('ddd')
				data.tomorrow.date = tomorrow.format('M/D')
				data.tomorrow.week_day = tomorrow.format('ddd')

				data.data = records.map(item => {
					const uvLevel = item.uv_level
					const utsuLevel = item.utsu_day
					const kansoLevel = item.kanso_level
					const pollenLevel = item.pollen_level
					const infulLevel = item.inful_level
					const netuLevel = item.netu_move
					const tempDifferenceLevel = isNaN(Number(item.temp_difference_level)) ? '1' : item.temp_difference_level

					const uvComment = this.getHealthComment('uv', uvLevel)
					const utsuComment = this.getHealthComment('utsu', utsuLevel)
					const kansoComment = this.getHealthComment('kanso', kansoLevel)
					const pollenComment = this.getHealthComment('pollen', pollenLevel)
					const infulComment = this.getHealthComment('inful', infulLevel)
					const netuComment = this.getHealthComment('netu', netuLevel)
					const tempDifferenceComment = this.getHealthComment('tempDifference', tempDifferenceLevel)

					return {
						'uv_level': uvLevel,
						'uv_comment': uvComment,
						'utsu_day': utsuLevel,
						'utsu_comment': utsuComment,
						'kanso_level': kansoLevel,
						'kanso_comment': kansoComment,
						'pollen_level': pollenLevel,
						'pollen_comment': pollenComment,
						'inful_level': infulLevel,
						'inful_comment': infulComment,
						'netu_move': netuLevel,
						'netu_comment': netuComment,
						'temp_difference_level': Math.abs(Number(tempDifferenceLevel)),
						'temp_difference_comment': tempDifferenceComment
					}
				})
			}
			return data
		}
	},
	methods: {
		// map Vuex actions
		...mapActions('weatherForecast', [SET_HEALTH_WEATHER]),

		// 必要なデータをAPIリクエストする処理
		getWeatherData () {
			const today = moment()
			const tomorrow = moment().add(1, 'days')
			const from = today.set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).unix()
			const to = tomorrow.set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).unix()
			// console.log(today.format('YYYYMMDD HH:mm:SS'))
			// console.log(tomorrow.format('YYYYMMDD HH:mm:SS'))
			// console.log(from)
			// console.log(to)

			this.isLoading = true

			// 日単位の気象データ取得API
			this.SET_HEALTH_WEATHER({
				user_id: this.userId,
				user_token: this.userToken,
				from: from,
				to: to
			}).then(res => {
				// console.log(res)

				// API通信でOKでもエラーになるケース
				if (this.healthWeatherData.records && this.healthWeatherData.records.length !== 2) {
					console.log('Error: There is no daily data for 2 days.')
					this.isError = true	// データが2日分ないケース
				}
			}).catch(error => {
				// NOTE: エラー処理
				console.log('Error: ', error)
				this.isError = true
			}).finally(() => {
				this.isLoading = false
			})
		},

		// 健康天気指数コメント
		getHealthComment (type, level) {
			level = isNaN(level) ? 1 : level
			let commentList = null

			const UV_COMMENT_LIST = {
				'5': ['極めて強い、日中の外出は控えて', '肌や眼にUVダメージを受ける危険も', '短時間で肌が赤くなってしまうかも', '外出時はUV対策を完璧にしましょう', 'SPFとPAの高い日焼け止め必須'],
				'4': ['万全の対策で肌や眼を守りましょう', '非常に強い、万全なUV対策が必須！', '朝夕のお出掛けにしたほうが良さそう', 'サングラスなどでアイケアも忘れずに', '出来る限り昼前後の外出は控えて'],
				'3': ['外出時は帽子をかぶりましょう', '無防備な状態は肌や眼のダメージに', '紫外線に敏感なの人は特に気をつけて', '日差しを浴びすぎないように注意を', '肌のUVケア・アイケアを忘れずに'],
				'2': ['強いレベル、日陰を上手に利用して', 'やや強い、外出の際は帽子を忘れずに', 'やや強い、UVケアを忘れずに', 'やや強い、紫外線の浴びすぎは禁物', 'うっすら日差しでも肌老化の原因に'],
				'1': ['弱い、安心して戸外でも過ごせそう', '肌や眼への影響を気になくてもOK', '特にUVケアをしなくても大丈夫そう', '美白を目指す人も安心できる一日に', '弱い、日焼けの心配なく過ごせそう']
			}

			const UTSU_COMMENT_LIST = {
				'5': ['気圧が大きく低下、無理をせず休息を', '低気圧が接近、身体を休めましょう'],
				'4': ['気圧が低下、体調の変化に気をつけて', '気圧が低下、ゆったりと過ごそう'],
				'3': ['注意が必要、リフレッシュを心がけて', '注意な日、音楽を聴いてリラックスを'],
				'2': ['少し注意が必要、バランス良い食事を', 'やや注意、規則正しい生活で予防を'],
				'1': ['太陽の光を浴びて前向きな気持ちに', '晴れて気圧も安定、比較的安心な1日']
			}

			const KANSO_COMMENT_LIST = {
				'5': ['空気は超乾燥、定期的に喉を潤して', '空気がかなり乾燥、最大限の保湿を'],
				'4': ['空気がからから、乾燥肌に注意を', '乾燥しやすい日、風邪に気をつけて'],
				'3': ['空気が乾燥気味、加湿器がおすすめ', '空気が乾燥、のど飴があると安心'],
				'2': ['空気がやや乾燥、注意が必要な時間も', 'しっとりした空気で安心できそう'],
				'1': ['乾燥を気にせずに過ごせるでしょう', '乾燥の心配なく、過ごしやすい日']
			}

			const POLLEN_COMMENT_LIST = {
				'5': ['極めて花粉が多い、室内で過ごそう'],
				'4': ['非常に多い、昼間の外出は控えて', '非常に多い、外出にはメガネが安心', '非常に多い、換気扇にはカバーが安心', '発症していない人もマスクで予防を', '鼻を洗う時は生理食塩水がオススメ'],
				'3': ['家に入る時は、花粉を払い落とそう', '花粉シーズンはメガネがおすすめ', 'ウール服は花粉がつきやすいので注意', '加湿器やマスクで乾燥を防ぎましょう', '鼻炎薬を飲んだら車の運転は控えて'],
				'2': ['マスクは花粉を1/3～1/6に軽減', '花粉が付着しにくいナイロン製の服を', 'やや多い、外出にはメガネがおすすめ', '外干しの洗濯物は花粉を叩き落そう', 'やや多い、布団を干すと花粉が侵入'],
				'1': ['少ない、十分な睡眠は花粉症予防にも', 'お酒は鼻づまりを悪化させることも', '風邪は花粉症を悪化させることも', '喫煙は症状を悪化させる恐れあり', '日ごろからの適度な運動で免疫力UP']
			}

			const INFUL_COMMENT_LIST = {
				'5': ['厳重警戒、手洗いやうがいを忘れずに', 'ウイルスが活発化、十分な予防策を'],
				'4': ['警戒、マスクを付けて十分な対策を', '感染リスクの高い日、うがい手洗いを'],
				'3': ['十分注意、体調管理には気をつけて', 'ウイルスの活動が活発になりやすい日'],
				'2': ['やや注意、十分な睡眠で免疫力UP', 'やや注意、基本的な予防策は忘れずに'],
				'1': ['ウイルスの量が少なく安心できそう', '心配なし、安心して過ごせそう']
			}

			const NETU_COMMENT_LIST = {
				'5': ['危険、空調を利用し室内で過ごして'],
				'4': ['熱中症に厳重警戒、外出は控えて', '水分はもちろん塩分補給も忘れずに', '厳重警戒、子供やお年寄りは特に注意'],
				'3': ['休息と水分補給、帽子も忘れずに', '体調不調時は特に無理は禁物です'],
				'2': ['十分注意、熱中症の発生の可能性あり', '屋外労働や作業では、特に注意が必要'],
				'1': ['ほぼ安全、通常は熱中症の危険なし', '激しい運動をしないかぎりは安全な日', '晴れた日の車内温度は高くなる恐れも']
			}

			const TEMP_DIFFERENCE_COMMENT_LIST = {
				'4': ['気温が大幅アップ⤴身体を大切に', '気温急上昇⤴疲れをためないように'],
				'3': ['グンと気温上昇⤴体調管理をシッカリ', '大きく気温上昇⤴服装の調整に注意を'],
				'2': ['やや気温上昇。室内を適温に保とう', '気温アップ。気温に合わせた服装を'],
				'1': ['大きな変化なし。朝晩の気温も確認を', '気温差小さい。湿度の変化もチェック'],
				'-2': ['やや気温低下。服装選びは慎重に', '気温ダウン。体調を整えておこう'],
				'-3': ['かなり気温低下⤵食事で体を温めよう', '大きく気温低下⤵体をほぐしてみては'],
				'-4': ['急激な気温低下⤵体調と服装に注意！', '気温大幅ダウン⤵十分な睡眠と栄養を'],
				'-5': ['ガクッと気温低下⤵体の冷えに要注意', '気温急降下⤵お風呂で身体を温めよう']
			}

			switch (type) {
				case 'uv':
					// 紫外線レベルの処理
					commentList = UV_COMMENT_LIST
					break
				case 'utsu':
					// うつ予報の処理
					commentList = UTSU_COMMENT_LIST
					break
				case 'kanso':
					// 乾燥レベルの処理
					commentList = KANSO_COMMENT_LIST
					break
				case 'pollen':
					// 花粉レベルの処理
					commentList = POLLEN_COMMENT_LIST
					break
				case 'inful':
					// インフルレベルの処理
					commentList = INFUL_COMMENT_LIST
					break
				case 'netu':
					// 熱中症レベルの処理
					commentList = NETU_COMMENT_LIST
					break
				case 'tempDifference':
					// 寒暖差指数レベルの処理
					commentList = TEMP_DIFFERENCE_COMMENT_LIST
					break
			}
			const rand = Math.floor(Math.random() * commentList[level].length)
			return commentList[level][rand]
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

$font-size-13: 0.812rem;

.health-weather {
	position: relative;
	min-height: 145px;

	&__wrapper {
		margin-top: $spacing-20;
		padding: 0 $spacing-10;
	}

	&__title {
		margin: 0 0 $spacing-10;
		border-bottom: 2px solid $primary;
		color: $primary;
	}

	&__tab {
		margin: 0 auto;
		padding: 0;
		border-bottom: 1px solid $line-primary;

		/deep/ .segment {
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-shadow: none;

			li {
				margin: 0;
				padding: 0;
				width: 49.3%;
				font-size: $font-size-14;
			}

			&__button {
				margin: 0;
				padding: 0;
				border: none;
				border-radius: 0;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				height: 37px;
				line-height: 37px;
				background-color: $background-secondary;
				color: $text-primary;
				font-family: $family-primary;
			}

			:checked + .segment__button {
				background-color: $primary;
				color: $text-seconday;
			}
		}

	}

	&__day {
		width: 100%;
		overflow: hidden;
		box-sizing: border-box;
	}

	&__list {
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		position: absolute;
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		z-index: -1;
		top: 0;
		left: 0;
		opacity: 0;

		&.is-active {
			position: relative;
			z-index: 1;
			opacity: 1;
			transition: 0.4s opacity ease-in, 0.2s transform ease;
		}

		li {
			border-bottom: 1px solid $line-primary;
			display: flex;
			justify-content: center;
			align-items: center;
			&.is-order0 {
				display: none;
			}
			&.is-order1 {
				order: 1;
			}
			&.is-order2 {
				order: 2;
			}
			&.is-order3 {
				order: 3;
			}
			&.is-order4 {
				order: 4;
			}
			&.is-order5 {
				order: 5;
			}
			&.is-order6 {
				order: 6;
			}
			&.is-order7 {
				order: 7;
			}
		}

		&--title {
			margin: $spacing-14 0;
			padding: 0 $spacing-4;
			min-width: 125px;
			font-weight: normal;
			font-size: $font-size-14;
			font-family: $family-primary;
			text-align: center;
			box-sizing: border-box;

			@media (max-width: (370px)) {
				font-size: $font-size-12;
				min-width: 100px;
			}

		}

		&--contents {
			padding: $spacing-10 0 $spacing-8 $spacing-8;
			text-align: center;
			background: linear-gradient(to bottom, $background-primary 14px, $line-primary 14px, $line-primary calc(100% - 14px), $background-primary calc(100% - 14px)) left top / 1px 100% no-repeat;
			box-sizing: border-box;

			i {
				margin: 0 auto;
			}

			p {
				margin: $spacing-4 0 0;
				width: 18em;
				font-size: $font-size-13;
				font-family: $family-primary;

				@media (max-width: (370px)) {
					font-size: $font-size-12;
				}

				@media (max-width: (350px)) {
					font-size: $font-size-10;
				}
			}
		}
	}

	&__error {
		margin: 0;

		&--link {
			color: $primary;
			font-weight: bold;
			text-decoration: none;
		}

		// Webviewエラー
		&.is-webview {
			position: relative;
			padding-top: 40.8451%;
		}
	}

	// ローディング
	.redraw-progress {
		position: absolute;
		top: 0;
		left: 0;
	}
}

// アニメーション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

// スプライト
.sprite_health_weather_icon {
	margin: 0 auto;
	background-image: url(~@/assets/img/sprite_health_weather_icon.png);
	background-repeat: no-repeat;
	background-size: (340px / 2) (1064px / 2);
	display: block;

	width: 160px;
	height: 28px;

	.sprite_health_weather_icon_lv {
		@extend .sprite_health_weather_icon;
		margin: 0;

		&[class$="lv0"] {
			width: 0;
		}

		&[class$="lv1"] {
			width: 28px;
		}

		&[class$="lv2"] {
			width: calc(28px * 2 + 5px);
		}

		&[class$="lv3"] {
			width: calc(28px * 3 + 10px);
		}

		&[class$="lv4"] {
			width: calc(28px * 4 + 15px);
		}

		&[class$="lv5"] {
			width: 160px;
		}
	}

	&.kanso_icon {
		background-position: (-10px / 2) (-238px / 2);

		.kanso_icon_lv {
			background-position: (-10px / 2) (-162px / 2);
		}
	}

	&.inful_icon {
		background-position: (-10px / 2) (-86px / 2);

		.inful_icon_lv {
			background-position: (-10px / 2) (-10px / 2);
		}
	}

	&.utsu_icon {
		background-position: (-10px / 2) (-694px / 2);

		.utsu_icon_lv {
			background-position: (-10px / 2) (-618px / 2);
		}
	}

	&.pollen_icon {
		background-position: (-10px / 2) (-542px / 2);

		.pollen_icon_lv {
			background-position: (-10px / 2) (-466px / 2);
		}
	}

	&.uv_icon {
		background-position: (-10px / 2) (-846px / 2);

		.uv_icon_lv {
			background-position: (-10px / 2) (-770px / 2);
		}
	}

	&.netu_icon {
		background-position: (-10px / 2) (-390px / 2);

		.netu_icon_lv {
			background-position: (-10px / 2) (-314px / 2);
		}
	}

	&.temp_icon {
		background-position: (-10px / 2) (-998px / 2);

		.temp_icon_lv {
			background-position: (-10px / 2) (-922px / 2);
		}
	}
}
</style>
